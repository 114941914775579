import React from "react";
import ResumeInfoGenerator from "./ResumeInfoGenerator";
import ContactGroup from "./ContactGroup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa6";

import { FaCalendarDays } from "react-icons/fa6";
import { toast } from "react-toastify";
import { ResumeData } from "../../type/ResumeType";

const InformationBlock = ({ data }: any) => {
    const { date_of_birth, first_name, last_name, location, summary, contact } = data as unknown as ResumeData;

    const name = (first_name || "") + " " + (last_name || "");

    const filteredLocation = location
        ? Object.fromEntries(Object?.entries(location).filter(([_, value]) => value !== null))
        : {};

    let locationString = ``;
    if (Object.keys(filteredLocation).length !== 0) {
        locationString = `${location?.street_address && location.street_address + " "}${
            location?.city && location.city + " "
        }${location?.code && location.code + " "}${location?.state && location.state + " "}${
            location?.country && location.country + " "
        }`;
    }

    let copyData = [];

    if (contact?.phone) {
        copyData.push(`Phone: ${contact.phone}`);
    }
    if (contact?.email) {
        copyData.push(`Email: ${contact.email}`);
    }

    const formattedLinks = contact
        ? Object.entries(contact.socials)
              .filter(([key, value]) => value !== null)
              .map(([key, value]) => `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`)
        : [];

    copyData = [...copyData, ...formattedLinks];

    const candidateInformation = `HRHouz resume scanner Candidate Information results for ${name}’s Resume:\n\nName: ${name}\n${
        date_of_birth ? "Date of Birth: " + date_of_birth + "\n" : ""
    }${locationString.length > 0 ? "Address: " + locationString + "\n" : ""}${copyData.join("\n")}\n\n${
        summary && "Summary: " + summary
    }\n\npowered by HRHouz (https://www.hrhouz.com/)`;

    return (
        <div>
            <div className="mb-4 flex min-w-[4rem] items-center gap-x-2 lg:min-w-[6rem]">
                <h1 className="font-semibold">Candidate Information</h1>
                <CopyToClipboard onCopy={() => toast.success(` Copied!`)} text={candidateInformation}>
                    <button className="text-primary">
                        <FaRegCopy />
                    </button>
                </CopyToClipboard>
            </div>
            {name && <ResumeInfoGenerator title={"Name"} data={name} />}

            <ContactGroup title="Contact" data={data} />
            {summary && <ResumeInfoGenerator paragraph title={"Summary"} data={summary} />}
            {date_of_birth && (
                <ResumeInfoGenerator logo={<FaCalendarDays />} title={"Date of Birth"} data={date_of_birth} />
            )}
            {locationString.length > 0 && <ResumeInfoGenerator location title={"Location"} data={locationString} />}
        </div>
    );
};

export default InformationBlock;
